<template>
  <div class="items-page">
    <div v-loading="is_pending" class="items-page-form">
        <div v-if="!show_summary_table" class="items-page-form-field">
            <h1>חיפוש מוצר</h1>
            <el-select style="width:100%;" filterable v-model="selected_item_uid" class="m-2" placeholder="בחירת פריט" size="large">
                <el-option
                    v-for="item in items"
                    :key="item.uid"
                    :label="`${item['פריט']} ${item['שם פריט']}`"
                    :value="item.uid" 
                />
            </el-select>
        </div>
        <div v-if="data.selected_item" class="items-page-item-card">
            <h2>מזהה פריט: {{data.selected_item['פריט']}}</h2>
            <br>
            <h2>שם : {{data.selected_item['שם פריט']}}</h2>
            <br>
            <h2>{{data.selected_item['שם פריט לועזית']}}</h2>
             <div @click="handle_reset_choose" class="close-btn">
                <i class="material-icons">close</i>
             </div>
        </div>
         <div v-if="data.selected_item" class="items-page-form-field">
            <h2>הזן משקל בק"ג</h2>
            <el-input type="number" v-model="weight_first" :step="0.1" placeholder="הזן משקל" />
         </div>
         <div v-if="data.selected_item" class="items-page-form-field">
            <h2>הזן את אותו המשקל פעם נוספת (אימות)</h2>
            <el-input type="number" v-model="weight_second" :step="0.1" placeholder="הזן משקל" />
         </div>
         <div class="items-page-form-field row">
            <el-button v-if="!show_summary_table" @click="handle_go_back" type="danger">חזור</el-button>
            <el-button @click="show_summary_table = true" style="margin:0;" v-if="!show_summary_table && !data.selected_item && !is_pending && counted_selected.length > 0" type="primary">סיים ספירה</el-button>
            <el-button style="margin:0;"  v-if="data.selected_item && !is_pending" @click="handle_submit" type="primary">המשך</el-button>
            <el-button style="margin:0;" v-if="is_pending"  type="primary" disabled>המשך</el-button>
         </div>
         <table v-if="show_summary_table">
            <tr>
                <th>פריט</th>
                <th>שם פריט</th>
                <th>שם פריט לועזית</th>
                <th>משקל</th>
            </tr>
            <template v-for="item in counted_selected" :key="item.uid">
                <tr>
                    <td>{{item['פריט']}}</td>
                    <td>{{item['שם פריט']}}</td>
                    <td>{{item['שם פריט לועזית']}}</td>
                    <td>{{item['weight']}}</td>
                </tr>
            </template>
         </table>
        <el-button v-if="show_summary_table" @click="handle_exist" style="width:100%; margin-top:5px;" type="warning">יציאה</el-button>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { watch } from '@vue/runtime-core'
import {slide_pop_error} from '../../../../../Methods/Msgs'
import {counted_selected} from '../store'
import Swal from 'sweetalert2'
export default {
    props:['items','reset_item_page'],
    emits:['go_to_branches_page','submit','stop_reset','back_to_start','submit_update','submit_delete'],
    setup(props,{emit}){
        
        
        const show_summary_table = ref(false)
        const is_pending = ref(false)
        const err_msg = ref('')

        const selected_item_uid = ref('')

        const weight_first = ref('')
        const weight_second = ref('')

        const data = ref({
            selected_item:null,
            weight:'',
        })

        const validation = () => {
            if(!data.value.selected_item){
                err_msg.value = 'עליך לבחור מוצר!'
                return false
            }
            if(!weight_first.value || !weight_second.value){
                err_msg.value = 'עליך להזין משקלים!'
                return false
            }
            if(weight_first.value != weight_second.value){
                err_msg.value = 'עליך להזין משקלים זהים!'
                return false
            }

            return true
        }

        const handle_go_back = () => {
            emit('go_to_branches_page')
        }

        const handle_reset_choose = () => {
            show_summary_table.value = false
            data.value = {
                selected_item:null,
                weight:'',
            }
            weight_first.value = ''
            weight_second.value = ''
            selected_item_uid.value = ''
        }

        const check_if_item_exist = () => {   
            const index = counted_selected.value.findIndex(item => item.uid ==  data.value.selected_item.uid)
            if(index != -1){
                return false
            }else{
                return true
            }
        }

        const handle_submit = () => {
            is_pending.value = true
            if(!validation()){
                slide_pop_error(err_msg.value)
            }
            else if(!check_if_item_exist()){
                let previous_weight = ''
                const index = counted_selected.value.findIndex(_item=>_item.uid == selected_item_uid.value)
                if(index!=-1) {
                    previous_weight = counted_selected.value[index].weight
                }
                Swal.fire({
                    title: `המוצר ${data.value.selected_item['שם פריט']} כבר נספר על סך משקל ${previous_weight}!`,
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'להוסיף לכמות הקיימת',
                    denyButtonText: `החלף בכמות החדשה`,
                    cancelButtonText:'ביטול ומחיקה'
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        const index = counted_selected.value.findIndex(item => item.uid == data.value.selected_item.uid )
                        if(index!=-1){
                            counted_selected.value[index].weight =  String(Number(counted_selected.value[index].weight) + Number(weight_first.value))
                            emit('submit_update',counted_selected.value[index])
                        }
                    }else if (result.isDenied) {
                        const index = counted_selected.value.findIndex(item => item.uid == data.value.selected_item.uid )
                        if(index!=-1){
                            counted_selected.value[index].weight = String(weight_first.value)
                            emit('submit_update',counted_selected.value[index])
                        }
                    }else if(result.isDismissed){
                        console.log('checkk');
                        const index = counted_selected.value.findIndex(item => item.uid == data.value.selected_item.uid )
                        if(index!=-1){
                            const item = counted_selected.value[index]
                            counted_selected.value.splice(index,1)
                            emit('submit_delete',item)
                        }
                    }
                })
            }
            else{
                data.value.weight = weight_first.value
                emit('submit',data.value)
            }
            is_pending.value = false
        }

        const handle_exist = () => {
            counted_selected.value = []
            handle_reset_choose()
            emit('back_to_start')
        }

        watch(selected_item_uid,()=>{
            if(selected_item_uid.value){
                const index = props.items.findIndex(_item=>_item.uid == selected_item_uid.value)
                if(index!=-1) {
                    data.value.selected_item = props.items[index]
                }
            }
        })

        watch(props,()=>{
            if(props.reset_item_page){
                handle_reset_choose()
                emit('stop_reset')
            }
        })
        return{
            data,
            selected_item_uid,
            weight_first,
            weight_second,
            handle_submit,
            handle_go_back,
            handle_reset_choose,
            is_pending,
            counted_selected,
            show_summary_table,
            check_if_item_exist,
            handle_exist
        }
    }
}
</script>

<style scoped>
    .items-page{
        width: 100%;
        height: 100%;
    }
    .items-page-form{
        width: 100%;
        max-width: 500px;
        height: fit-content;
        margin: 50px auto;
    }
    .items-page-form-field{
        width: 100%;
        height: fit-content;
        margin-bottom: 5px;
    }
    .items-page-form-field.row{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .items-page-item-card{
        position: relative;
        width: 100%;
        user-select: none;
        height: fit-content;
        background: #fff;
        color: #333;
        border-radius: 10px;
        padding: 5px;
    }
    .close-btn{
        position: absolute;
        top: 5px;
        left: 5px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: var(--danger);
        cursor: pointer;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
</style>